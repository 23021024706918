/* eslint-disable react/prop-types */
import 'intersection-observer';

import * as React from 'react';
import styled from 'styled-components';
import MainLayout from 'components/Layout/MainLayout';
import { withHomePageSection } from 'components/withHomePageSection';
import { ClassScheduleOfLevel } from 'shared/CourseLanding/Detail/ClassScheduleOfLevel';
import { ClassTeacherOfLevel } from 'shared/CourseLanding/Detail/ClassTeacherOfLevel';
import { FAQ } from 'shared/CourseLanding/Detail/CourseFAQ';
import { GGSheetScholarships } from 'shared/CourseLanding/Detail/DetailScholarships';
import { Header as DetailHeader } from 'shared/CourseLanding/Detail/Header';
import { OtherClasses } from 'shared/CourseLanding/Detail/OtherClasses';
import { GCMSRequirements } from 'shared/CourseLanding/Detail/Requirements';
import { SyllabusDetail } from 'shared/CourseLanding/Detail/SyllabusDetail';
import { WhatYouWillGet } from 'shared/CourseLanding/Detail/WhatYouWillGet';
import { useAllTeacherData } from 'shared/DolTeachers/AllDolTeachers';
import HeaderSchedulePage from 'shared/Header/HeaderSchedulePage';
import MySEO from 'shared/MySEO';
import StudentReviews from 'shared/StudentCourseLanding/StudentReviews';
import { fromScreen } from 'utils/media-query/responsive.util';
import { getName } from 'utils';
import { FixedTopBarScholarshipScheduleNOffline } from '../shared/SchedulePage/FixedTopBarScholarship/FixedTopBarScholarshipScheduleNOffline';
import { colorsV2 } from '../style/colors-v2';
import { useWindowSize } from '../utils/hooks/useWindowSize';
import { StickyCourseHeader } from '../shared/CourseLanding/Detail/StickyCourseHeader';
import { StickyCourseProvider } from '../shared/CourseLanding/Detail/StickyCourseContext';
import { StickyGGSheetScholarships } from '../shared/CourseLanding/Detail/StickyGGSheetScholarships';
import { LevelContext } from '../shared/Marketing/Online/DolRegister/components/withProgramByLevel';
import HallOfFame, { HOFContainer } from '../shared/HallOfFame';

const ROUTE_COURSE = {
  'Khóa IELTS cơ bản': 'ielts-co-ban',
  'Khóa IELTS nâng cao': 'ielts-nang-cao',
  'Khóa IELTS cấp tốc': 'ielts-cap-toc'
};

const Wrapper = styled.div`
  .scholarship-desktop-only {
    display: none;
  }
  .section + .section {
    padding-top: 26px;
  }
  section {
    position: relative;
    z-index: 1;
    &:nth-child(n + 3) {
      z-index: 2;
    }
  }
  .breadcrumb {
    a:last-child > p {
      color: ${colorsV2.black80};
    }
  }
  ${HOFContainer} {
    button {
      width: 100%;
    }
  }

  ${fromScreen(776)} {
    .section + .section {
      padding-top: 32px;
    }
  }

  ${fromScreen(1144)} {
    .scholarship-desktop-only {
      display: block;
    }
    #fixed-topbar-scholarship {
      display: none;
    }
    section {
      display: block;
      position: relative;
      z-index: 1;
      &:nth-child(n + 3) {
        display: block;
      }
      &:nth-child(n + 3):not(:nth-child(7)):not(:nth-child(8)) {
        z-index: 1;
      }
    }
    section:nth-child(7),
    section:nth-child(8) {
      z-index: 1;
    }
    #what-you-will-get,
    #class-teacher-of-level,
    #faq-section,
    #class-schedule-of-level,
    #other-classes,
    #course-landing-detail-requirements,
    #syllabus-detail,
    ${HOFContainer} {
      max-width: 712px;
    }
    #course-landing-student-reviews {
      width: 712px;
      .card-review {
        min-width: 712px;
        max-width: 712px;
      }
    }
  }
`;

const StudentReviewsWrapper = styled.div`
  .see-more-review {
    button {
      width: 100%;
    }
  }
`;

const HallOfFameSection = withHomePageSection(
  () => <HallOfFame isDetailCourse />,
  {
    style: {
      paddingTop: 32,
      paddingBottom: 32
    },
    selfish: true
  }
);

const CourseLanding2021DetailTemplate = React.memo(props => {
  const { pageContext } = props;
  const {
    level,
    syllabus,
    otherClasses,
    rating,
    seo = {
      title: 'DOL English - Chuẩn Đầu Ra - Chuẩn Tư Duy',
      description: ''
    }
  } = pageContext;
  const { getTeacherOfLevel } = useAllTeacherData();

  const teachers = getTeacherOfLevel(level.level);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  useWindowSize(forceUpdate);

  const breadCrumbList = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    '@id': `${process.env.SITE_URL + level.slug}#breadcrumb`,
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        item: {
          '@type': 'WebPage',
          '@id': process.env.SITE_URL,
          url: process.env.SITE_URL,
          name: 'Trang chủ'
        }
      },
      {
        '@type': 'ListItem',
        position: 2,
        item: {
          '@id': `${process.env.SITE_URL}/khoa-hoc-ielts`,
          url: `${process.env.SITE_URL}/khoa-hoc-ielts`,
          name: 'Khoá học IELTS'
        }
      },
      {
        '@type': 'ListItem',
        position: 3,
        item: {
          '@id': `${process.env.SITE_URL}/${ROUTE_COURSE[level.classGroup]}`,
          url: `${process.env.SITE_URL}/${ROUTE_COURSE[level.classGroup]}`,
          name: getName(level.classGroup)
        }
      },
      {
        '@type': 'ListItem',
        position: 4,
        item: {
          '@id': process.env.SITE_URL + level.slug,
          url: process.env.SITE_URL + level.slug,
          name: getName(level.levelDisplay)
        }
      }
    ]
  };

  return (
    <LevelContext.Provider value={level.level}>
      <MainLayout
        noHeader
        hideSideCTA
        renderCustomHeader={() => <HeaderSchedulePage />}
        showFooterRegister={false}
      >
        <MySEO
          title={seo.title}
          description={seo.description}
          featureImg={seo.featureImg}
          h1={getName(level.levelDisplay)}
          breadCrumbSchema={breadCrumbList}
          slug={level.slug}
        />

        <Wrapper>
          {/* <FixedTopBarScholarshipScheduleNOffline /> */}
          {/*<LandingCourseDetailPromotion />*/}
          <FixedTopBarScholarshipScheduleNOffline />
          <StickyCourseProvider>
            <DetailHeader rating={rating} level={level} teachers={teachers} />
            <div className="scholarship-desktop-only">
              <StickyCourseHeader rating={rating} level={level} />
              <StickyGGSheetScholarships
                level={level}
                syllabus={syllabus}
                rating={rating}
              />
            </div>

            <GGSheetScholarships
              level={level}
              syllabus={syllabus}
              rating={rating}
              showCommitment={level?.level !== 'WS8'}
            />
          </StickyCourseProvider>

          <WhatYouWillGet level={level} />
          <SyllabusDetail syllabus={syllabus} level={level} />
          <ClassTeacherOfLevel
            levels={[level.level]}
            levelGroupName={level.levelDisplay}
          />
          <Reviews />
          <HallOfFameSection />
          <ClassScheduleOfLevel level={level} />
          <GCMSRequirements />
          {/*<div id="course-landing-detail-includes-slot" />*/}
          {otherClasses.length > 0 ? (
            <OtherClasses
              classes={otherClasses}
              classGroup={level.classGroup}
            />
          ) : null}
          <FAQ />
          <div
            id={'course-landing-detail-bottom'}
            style={{
              position: 'absolute',
              bottom: 32,
              left: 0
            }}
          />
        </Wrapper>
        {/* <CallPhoneFab className="mobile-only tablet-only d-flex" /> */}
        {/*<CustomMessengerFab className="mobile-only tablet-only d-flex" />*/}
      </MainLayout>
    </LevelContext.Provider>
  );
});

const Reviews = withHomePageSection(
  () => (
    <StudentReviewsWrapper>
      <StudentReviews
        program="IELTS"
        titleDetailCourse="Review học viên khóa IELTS tại DOL"
        columns={{ default: 2, 775: 1 }}
      />
    </StudentReviewsWrapper>
  ),
  { selfish: true, style: { backgroundColor: colorsV2.white100 } }
);

CourseLanding2021DetailTemplate.propTypes = {};

export default CourseLanding2021DetailTemplate;
